import { Injectable } from '@angular/core';

export interface StandardContactSearch {
  contactNumber?: number | null
  contactName?: string | null
  address?: string | null
  com?: string | null
  id?: string | null
  fuzzySearch?: boolean | null
  activeUsers?: boolean
  caseId?: string | null
}

export interface AdvancedContactSearch {
  status: string | null
  roleTypeCatId: string | null
  roleTypeId: string | null
  caseId: string | null
  incUser: boolean
  countryId: string | null
  postalCodeFrom: string | null
  postalCodeTo: string | null
  businessSectorId: string | null
  distTypeId: string | null
  diciplineId: string | null
  diciplineCode: string | null
  activeUsers: boolean
}

export interface UserContactSearch {
  user: string | null
  activeUsers?: boolean
  caseId?: string | null
}


@Injectable({
  providedIn: 'root'
})
export class ContactSearchXMLService {

  constructor() { }


  public StandardSearchXML({ contactNumber = null, contactName = null, address = null, com = null, id = null, fuzzySearch = null, activeUsers = false, caseId = null }: StandardContactSearch): string {
    let contactNumberXML = !!contactNumber ? `<ContactNumber>${contactNumber}</ContactNumber>` : "";
    let contactNameXML = !!contactName ? `<ContactName>${contactName}</ContactName>` : "";
    let addressXML = !!address ? `<Address>${address}</Address>` : "";
    let comXML = !!com ? `<Com>${com}</Com>` : "";
    let idXML = !!id ? `<Id>${id}</Id>` : "";
    let fuzzySearchXML = !!fuzzySearch ? `<FuzzySearch>${fuzzySearch}</FuzzySearch>` : "";
    let activeUsersXML = activeUsers ? `<ActiveUsers>${activeUsers}</ActiveUsers>` : "";
    let caseIdXML = !!caseId ? `<CaseId>${caseId}</CaseId>` : "";


    let xml = `XML:<StandardSearch>${contactNumberXML}${contactNameXML}${addressXML}${comXML}${idXML}${fuzzySearchXML}${activeUsersXML}${caseIdXML}</StandardSearch>`;

    return xml;

  }

  public AdvancedSearchXML({
    status = null, roleTypeCatId = null, roleTypeId = null,
    caseId = null, incUser = false, countryId = null,
    postalCodeFrom = null, postalCodeTo = null, businessSectorId = null,
    distTypeId = null, diciplineId = null, diciplineCode = null,
    activeUsers = false
  }: AdvancedContactSearch): string {

    let statusXML = !!status ? `<Status>${status}</Status>` : "";
    let RoleTypeCategoryIdXML = !!roleTypeCatId ? `<RoleTypeCategoryId>${roleTypeCatId}</RoleTypeCategoryId>` : "";
    let RoleTypeIdXML = !!roleTypeId ? `<RoleTypeId>${roleTypeId}</RoleTypeId>` : "";
    let CaseIdXML = !!caseId ? `<CaseId>${caseId}</CaseId>` : "";
    let IncludeUsersXML = !!incUser ? `<IncludeUsers>${incUser}</IncludeUsers>` : "";
    let CountryIdXML = !!countryId ? `<CountryId>${countryId}</CountryId>` : "";
    let PostalCodeFromXML = !!postalCodeFrom ? `<PostalCodeFrom>${postalCodeFrom}</PostalCodeFrom>` : "";
    let PostalCodeToXML = !!postalCodeTo ? `<PostalCodeTo>${postalCodeTo}</PostalCodeTo>` : "";
    let BusinessSectorIdXML = !!businessSectorId ? `<BusinessSectorId>${businessSectorId}</BusinessSectorId>` : "";
    let DistributionTypeIdXML = !!distTypeId ? `<DistributionTypeId>${distTypeId}</DistributionTypeId>` : "";
    let DisciplineIdXML = !!diciplineId ? `<DisciplineId>${diciplineId}</DisciplineId>` : "";
    let DisciplineCodesXML = !!diciplineCode ? `<DisciplineCodes>${diciplineCode}</DisciplineCodes>` : "";
    let ActiveUsersXML = activeUsers ? `<ActiveUsers>${activeUsers}</ActiveUsers>` : "";

    let xml = `XML:<AdvancedSearch>${statusXML}${RoleTypeCategoryIdXML}${RoleTypeIdXML}${CaseIdXML}${IncludeUsersXML}${CountryIdXML}${PostalCodeFromXML}${PostalCodeToXML}${BusinessSectorIdXML}${DistributionTypeIdXML}${DisciplineIdXML}${DisciplineCodesXML}${ActiveUsersXML}</AdvancedSearch>`;

    return xml;



  }

  public UserSearchXML({user = null, activeUsers = true, caseId = null }: UserContactSearch): string  {

    let userXML = !!user ? `<User>${user}</User>` : "";
    let activeUsersXML = activeUsers ? `<ActiveUsers>${activeUsers}</ActiveUsers>` : "";
    let caseIdXML = caseId ? `<CaseId>${caseId}</CaseId>` : "";

    let xml = `XML:<UserSearch>${userXML}${activeUsersXML}${caseIdXML}</UserSearch>`;
    return xml;
  }

}
