import { Component, ComponentRef, OnDestroy, OnInit, EventEmitter, ElementRef, Input, Output, ViewChild, CUSTOM_ELEMENTS_SCHEMA, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalPositionStrategy, IComboFilteringOptions, IGridCellEventArgs, IRowSelectionEventArgs, ISimpleComboSelectionChangingEventArgs, IgxExpansionPanelComponent, IgxGridComponent, IgxSimpleComboComponent, PositionSettings, VerticalAlignment, IgxExpansionPanelHeaderComponent, IgxExpansionPanelTitleDirective, IgxButtonDirective, IgxRippleDirective, IgxExpansionPanelBodyComponent, IgxColumnComponent, IgxGridFooterComponent, IgxFilterCellTemplateDirective, IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxComboComponent, IgxComboItemDirective, IgxTooltipTargetDirective, IgxTooltipDirective, IgxBadgeComponent, IgxHintDirective, IgxIconModule, IgxButtonModule, IgxInputGroupModule, OverlaySettings } from '@infragistics/igniteui-angular';
import { DatalexClient, IBusinessSectorBE, IContactExtendedBE, IDisciplineBE, IRoleTypeBE, IRoleTypeCategoryBE } from '@datalex-software-as/datalex-client';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { AddressTypeIdEnum } from 'src/app/util/AddressTypeUtil';
import { ContactSearchXMLService } from 'src/app/util/contact-search-xml';

import { ContactSectorByCode, ContactSectorIdEnum, GetContactSectorById } from 'src/app/util/ContactSectorUtil';
import { RoleFilterProvider } from 'src/app/util/RoleFilters';
import { CompareCode, CompareNameAcending, CompareNameDecending, CompareNumberAcending, CompareNumberDecending, ComparePhoneExistingAcending, ComparePhoneExistingDecending, CompareStatusExistingAcending, CompareStatusExistingDecending } from 'src/app/util/SortCompare';
import { ContactSearchModalComponent } from '../../UI/modal-hub/modals/contact-search-modal/contact-search-modal.component';
import { CountryService } from 'src/app/services/country.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { Subscription } from 'rxjs';
import { IExpansionPanelCancelableEventArgs } from '@infragistics/igniteui-angular/lib/expansion-panel/expansion-panel.common';
import { FormsModule } from '@angular/forms';
import { GridFilterInputComponent } from '../../UI/grid-filter-input/grid-filter-input.component';
import { GridItemCountComponent } from '../../UI/grid-item-count/grid-item-count.component';
import { NgIf, NgTemplateOutlet, NgClass, CommonModule } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  standalone: true,
  imports: [CommonModule, NgIf, IgxExpansionPanelComponent, IgxExpansionPanelHeaderComponent, IgxInputGroupModule, IgxExpansionPanelTitleDirective, IgxButtonDirective, IgxButtonModule, IgxRippleDirective, IgxExpansionPanelBodyComponent, NgTemplateOutlet, IgxGridComponent, IgxColumnComponent, IgxGridFooterComponent, GridItemCountComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, IgxSimpleComboComponent, IgxComboComponent, IgxComboItemDirective, IgxTooltipTargetDirective, IgxTooltipDirective, IgxBadgeComponent, IgxHintDirective, NgClass, IgxIconModule],
})
export class ContactsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() dialogContactsSource!: any;
  @Input() contactSearchOrigin = "";
  @Input() sentSearchInput!: string;
  // @Input() roleContactSector!: string;
  @Input() queryParams!: any;

  @Output() sendFoundContact = new EventEmitter<IContactExtendedBE>();

  @ViewChild(IgxGridComponent) contactSearchGrid!: IgxGridComponent;
  @ViewChild('zipFromInput', { read: ElementRef }) zipFromInput!: ElementRef;
  @ViewChild('zipToInput', { read: ElementRef }) zipToInput!: ElementRef;

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;
  isDesktopDevice!: boolean;
  private subscriptions = new Subscription();

  isLoading: boolean = false;
  @Input() roleContactSector!: string;

  constructor(
    public screen: ScreenDimensionService,
    public combo: ComboSelectFunctionsService,
    private rf: RoleFilterProvider,
    private sys: SystemCacheService,
    private router: Router,
    private xml: ContactSearchXMLService,
    private dlxClient: DatalexClient,
    public gridMethods: GridMethodsService,
    private countryData: CountryService,
    public elRef: ElementRef,
    public screenSize: ScreenSizeService,
    public deviceService: DeviceService,
    private gms: GridMethodsService,
    public userRights: UserRightsProviderService) {

    this.caseId = null;
    this.roleTypeCatId = null;
    this.roleTypeId = null;
    this.selectedValueKeys = [];
    this.countryCode = null;
    this.postalCodeFrom = null;
    this.postalCodeTo = null;
    this.businessSectorId = null;
    this.distTypeId = null;

    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));

    this.subscriptions.add(this.screenSize.isTablet$.subscribe(isTablet => {
      this.isTablet = isTablet;
    }));

    this.subscriptions.add(this.screenSize.isDesktop$.subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));

    this.isDesktopDevice = this.deviceService.getDeviceType() === 'Desktop';
  }

  public panel!: IgxExpansionPanelComponent;

  public handleExpansion(evt?: { event: Event }) {
    this.headerTitle = "Skjul"

  }

  public handleExpandingAndCollapsing(event: IExpansionPanelCancelableEventArgs) {
  }

  public handleCollapse(evt?: { event: Event }) {
    this.headerTitle = "Nytt Søk"
  }

  headerTitle = "Skjul";

  newContactClick(evt: Event) {
    evt.stopImmediatePropagation();
    if (this.canCreateNewContact === false) return;
    this.onNewContact();
  }

  data: IContactExtendedBE[] = [];
  dataSource: IContactExtendedBE[] = [];
  filteredDatasource: IContactExtendedBE[] = [];
  public self!: ComponentRef<ContactSearchModalComponent>;
  public isModal: boolean = false;


  grid_message: string = "Søkeresultatet vil vises her"

  // positionSettings: PositionSettings = {


  // };

  // diciplineOverlaySettings: OverlaySettings = {
  //   positionStrategy: new GlobalPositionStrategy(
  //     this.positionSettings
  //   )
  // }

  getContactSector = GetContactSectorById;

  //standard search params
  _contactNumber!: string | null;
  set contactNumber(val: string | null) {
    this._contactNumber = val;
    this.setCanReset(val);
  }
  get contactNumber(): string | null {
    return this._contactNumber;
  }

  _contactName!: string;
  set contactName(val: string) {
    this._contactName = val;
    this.setCanReset(val);
  }
  get contactName(): string {
    return this._contactName;
  }

  _contactAddress!: string;
  set contactAddress(val: string) {
    this._contactAddress = val;
    this.setCanReset(val);
  }
  get contactAddress(): string {
    return this._contactAddress;
  }

  _coms!: string;
  set coms(val: string) {
    this._coms = val;
    this.setCanReset(val);
  }
  get coms(): string {
    return this._coms;
  }

  _nId!: string;
  set nId(val: string) {
    this._nId = val;
    this.setCanReset(val);
  }
  get nId(): string {
    return this._nId;
  }

  setCanReset(val: string | null) {
    if (val === "") {
      this.canReset = false;
      return
    }

    this.canReset = true;
  }

  _postalCodeRange!: string;
  set postalCodeRange(val: string) {
    this._postalCodeRange = val;
    let from = '';
    from = val.slice(0, 4);
    let to = '';
    to = val.slice(4);
    this.postalCodeFrom = from !== '' ? from : null;
    this.postalCodeTo = to !== '' ? to : null;
  }

  get postalCodeRange() {
    return this._postalCodeRange;
  }

  //advanced search params
  status: string | null = "A";
  roleTypeCatId!: string | null;
  roleTypeId!: string | null;
  caseId!: string | null;
  incUser!: boolean;
  countryCode!: string | null;
  countryId!: string | null;
  postalCodeFrom!: string | null;
  postalCodeTo!: string | null;
  businessSectors: IBusinessSectorBE[] = [];
  businessSectorId!: string | null;
  distTypeId!: string | null;
  diciplineId!: string | null;
  diciplineCode!: string | null;

  canCreateNewContact: boolean = false;

  hasRightToAddNewContact: boolean = false;

  internCategoryId = "6034096e-1574-4571-8b9a-4c663a1af63e";

  // cow search params
  coWorker!: string | null;

  activeUsers: boolean = true;
  fuzzySearch: boolean = this.deviceService.getDeviceType() === "Desktop" ? false : true;

  canReset = false;

  //Form handlers
  statusTypes: { label: string, id: string }[] = [
    { label: "Alle", id: "0" },
    { label: "Aktiv", id: 'A' },
    { label: "Passiv", id: 'P' }
  ]

  selectedContact: IContactExtendedBE | null = null;
  onContactOpen() {
    if (!this.selectedContact) {
      return
    };
    this.router.navigate(['contact', this.selectedContact.Id])
  }

  setCursor(ev: HTMLInputElement) {
    let _temp = ev.value.replaceAll('_', '').trim().replace('-', '');;
    if (_temp.length > 0) return
    ev.setSelectionRange(0, 0);
    ev.focus();
  }


  roleTypeCategories!: IRoleTypeCategoryBE[]
  onRoleTypeCategoryChange(event: ISimpleComboSelectionChangingEventArgs, roleSelect: IgxSimpleComboComponent, roleLabel: HTMLLabelElement) {
    if (event.newValue === this.internCategoryId) {
      this.incUser = true;
    } else { this.incUser = false; }

    this.roles = this.rf.filterRoleTypesByCategoryId(this.sys.roleTypes, event.newValue).sort(CompareCode);
    this.roleTypeId = null;
    if (event.newValue === undefined) {
      roleSelect.deselect();
      this.roles = [];
      this.roleTypeId = null;
      roleLabel.classList.remove("simple-select-label--active", "simple-select-label--selected");
    }
  }


  roles: IRoleTypeBE[] = [];
  diciplines!: IDisciplineBE[];
  // onDiciplineChange(event: IComboSelectionChangingEventArgs) {
  //   if(event.newValue === undefined) {
  //     this.diciplineCode = null;
  //   } else {
  //     this.diciplineCode = this.sys.discipline.filter((dicipline) => dicipline.Id.includes(event.newValue))[0].Code;
  //   }
  // }

  countries = this.countryData.allCountries;
  onCountryChange(event: ISimpleComboSelectionChangingEventArgs) {
    if (event.newValue === undefined) {
      this.countryCode = null;
      this.countryId = null;
    } else {
      this.dlxClient.GetCountryByCode(event.newValue).subscribe({
        next: (country) => {
          this.countryCode = event.newValue;
          this.countryId = country.Id;
        }
      })
    }

  }


  getSector(code: 'P' | 'M' | 'N' | 'S' | 'F' | 'O') {
    return ContactSectorByCode[code];
  }

  distTypes: any[] = [];

  // enables/disables form button
  _canSearch() {
    this.canSearch = ((!!this.contactNumber || !!this.contactName || !!this.contactAddress || !!this.coms || !!this.activeUsers || !!this.nId))
  }
  canSearch: boolean = true;
  searchTypeId: string = '0';

  searchTypes: { label: string, id: string }[] = [
    { label: "standard", id: '0' },
    { label: "Avansert", id: '1' },
    { label: "Medarbeider", id: '2' }
  ]
  onTypeReset() {
    this.searchTypeId = '0';
  }

  modalContactSelected = new EventEmitter<IContactExtendedBE>()

  defaultSector!: string;

  ngOnInit(): void {
    console.log("contacts init");
    console.log("oninit roleContactSector", this.roleContactSector);



    this.hasRightToAddNewContact = this.userRights.checkAccess(UserAreaEnum.CONTACTS).accessId === UserRightTypeEnum.FULL;

    this.getCoworkers();
    this.onTypeReset();
    if (this.contactSearchOrigin === "case-roles") {
      this.data = this.dialogContactsSource;
    }

    if (this.data.length === 1) {
      this.canCreateNewContact = true
    }
    this.contactName = this.sentSearchInput;

    if (this.roleContactSector === ContactSectorIdEnum.M) {

      if (this.dialogContactsSource.length === 0) {
        this.setInternContactsResults();
      }
      this.searchTypeId = "2";
      this.activeUsers = true
    } else {
      this.searchTypeId = "0";
    };

    document.addEventListener('keydown', this.handleKeydown);

    try {
      this.roleTypeCategories = this.sys.roleTypeCatergorys.sort(CompareCode);
      this.diciplines = this.sys.discipline.sort(CompareCode)
      console.log("this.diciplines try ", this.diciplines);

    } catch {
      this.sys.isReady.subscribe((sys) => {
        this.roleTypeCategories = sys.roleTypeCatergorys.sort(CompareCode);
        this.diciplines = sys.discipline.sort(CompareCode)
        console.log("this.diciplines catch", this.diciplines);

      })
    }

    this.dlxClient.GetBusinessSectors().subscribe({
      next: (res) => {
        this.businessSectors = res;
      }
    })
    this.dlxClient.GetDistributionTypes().subscribe({
      next: (res) => {
        this.distTypes = res;
      }
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.roleContactSector === ContactSectorIdEnum.M) {
      if (this.dialogContactsSource.length === 0) {
        this.setInternContactsResults();
      }
      this.searchTypeId = "2";
      this.activeUsers = true
    }
    else {
      this.searchTypeId = "0";
    };
  }

  coWorkerList: IContactExtendedBE[] = [];

  getCoworkers() {
    this.dlxClient.SearchContacts(this.setUserXML("%", true), ContactSectorIdEnum.M).subscribe({
      next: (res) => {
        this.coWorkerList = res;
      }
    })

  }

  setInternContactsResults() {
    this.search(this.setUserXML("%", true), ContactSectorIdEnum.M)
  }

  ngOnDestroy(): void {
    this.selectedContact = null;
    this.onTypeReset();

    removeEventListener('keydown', this.handleKeydown);
    this.subscriptions.unsubscribe();
    this.dialogContactsSource = [];
    this.sentSearchInput = "";
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      document.getElementById('submit')?.click();
    }
  }

  public selectedValueKeys: string[] = [];

  filterOptions: IComboFilteringOptions = {
    caseSensitive: false,
    filterable: true,
  }

  setDiciplineValueKeys(ids: string[]) {
    //const name = this.diciplines.filter(i => i.Id = id)[0].Name;
    let data: string[] = [];
    ids.forEach(id => {
      let _ = this.diciplines.filter(i => i.Id === id)[0].Code;
      data.push(_);
    })
    this.diciplineCode = data.join(",");
  }


  onSubmit() {
    let xml;
    switch (this.searchTypeId) {
      case '1':
        this.setDiciplineValueKeys(this.selectedValueKeys);
        xml = this.setAdvXML();
        break;
      case '2':
        xml = this.setUserXML();
        break;
      case '0':
        xml = this.setStdXML();
        break;
      default:
        console.warn("Serach type is:", this.searchTypeId)
        return;
    }
    this.search(xml)
  }


  search(searchString: string, contactSectorId: string = "00000000-0000-0000-0000-000000000000") {

    this.isLoading = true; // show loading spinner

    this.dlxClient.SearchContacts(searchString, contactSectorId).subscribe({
      next: this.onSearchResolve.bind(this),
      error: this.onSearchError.bind(this),
      complete: () => { this.isLoading = false; } // End loading spinner
    })
  }

  onSearchResolve(result: IContactExtendedBE[]) {
    this.data = this.filteredDatasource = result;
    this.isLoading = false;
    this.canSearch = false;
    this.grid_message = "Slutten av listen"

    this.dataSource = [...this.data];
    if (result.length === 0) {
      this.emptyGridMessage = this.grid_message = "Fant ingen kontakter, juster søket og prøv igjen"
    }

    if (result.length === 1) {
      this.sendMessage(this.dataSource[0])
    }
    this.canCreateNewContact = true;
  }

  sendMessage(contact: IContactExtendedBE) {
    this.sendFoundContact.emit(contact);
    // this.dialogContactsSource = [];
    // this.sentSearchInput = "";
  }

  onSearchError(result: any) {

  }

  onNewSearch() {
    this.canSearch = true;
    this.data = this.dataSource = this.filteredDatasource = [];
    this.grid_message = "Slutten av listen"
  }


  reset() {
    this.data = this.dataSource = [];
    this.contactNumber = null;
    this.contactName = ""
    this.contactAddress = ""
    this.coms = ""
    this.nId = ""
    this.activeUsers = true
    this.grid_message = "Slutten av listen"
    return
  }

  handleRowSelection(event: any) {

  }


  onRowClick(ev: Event, data: IContactExtendedBE) {
    this.router.navigate(['contact', data.Id])
  }
  onTelClick(ev: Event, data: IContactExtendedBE) {
    ev.stopImmediatePropagation();
    document.location.href = `tel:${data.PhoneNumberMobile}`;
  }
  onSMSClick(ev: Event, data: IContactExtendedBE) {
    ev.stopImmediatePropagation();
    document.location.href = `sms:${data.PhoneNumberMobile}`;
  }

  onMailClick(ev: Event, data: IContactExtendedBE) {
    ev.stopImmediatePropagation();
    document.location.href = `mailto:${data.EmailAddress}`;
  }

  onGPSClick(ev: Event, data: IContactExtendedBE) {
    ev.stopImmediatePropagation();
    const adr = data.ContactAddresses.filter(a => a.AddressTypeId === AddressTypeIdEnum.VisitAddress)[0];
    if (adr) {
      const address = `${adr.Address}, ${adr.PostalAddress ? adr.PostalAddress.PostCode : ""} ${adr.PostalAddress ? adr.PostalAddress.PostOffice : ""}`;
      window.open(`http://maps.google.com/?q=${address}`, '_blank');
    }
  }

  checkAddres(a: any) {
    return a.AddressTypeId === AddressTypeIdEnum.VisitAddress;
  }

  onNewContact() {
    if (this.queryParams) {

      this.router.navigate([`newcontact`], { queryParams: this.queryParams })
    } else {
      this.router.navigate(['newcontact']);
    }

    if (this.isModal) {
      this.self.destroy();
    }
  }

  //Mobile column sorting states
  col0SortingActive: boolean = false;
  col0SortAcending: boolean | null = null;

  col1SortingActive: boolean = false;
  col1SortAcending: boolean | null = null;

  col2SortingActive: boolean = false;
  col2SortAcending: boolean | null = null;

  col3SortingActive: boolean = false;
  col3SortAcending: boolean | null = null;

  onColumnSort(colIndex: number) {
    if (this.data) {
      switch (colIndex) {
        case 0:
          this.col1SortAcending = this.col2SortAcending = this.col3SortAcending = null;
          this.col1SortingActive = this.col2SortingActive = this.col3SortingActive = false;
          this.sortByContactNumber();
          break;
        case 1:
          this.col0SortAcending = this.col2SortAcending = this.col3SortAcending = null;
          this.col0SortingActive = this.col2SortingActive = this.col3SortingActive = false;
          this.sortByContactName();
          break;
        case 2:
          this.col0SortAcending = this.col1SortAcending = this.col3SortAcending = null;
          this.col0SortingActive = this.col1SortingActive = this.col3SortingActive = false;
          this.sortByContactPhone();
          break;
        case 3:
          this.col0SortAcending = this.col1SortAcending = this.col2SortAcending = null;
          this.col0SortingActive = this.col1SortingActive = this.col2SortingActive = false;
          this.sortByContactStatus();
          break;
      }
    }
  }

  sortByContactNumber() {
    const sortingState = this.sortingStateMachine(this.col0SortingActive, this.col0SortAcending);
    this.col0SortingActive = sortingState.isActive;
    this.col0SortAcending = sortingState.sortingDirection;
    let data = [...this.data];

    switch (this.col0SortAcending) {
      case true:
        this.dataSource = data.sort(CompareNumberAcending)
        break;
      case false:
        this.dataSource = data.sort(CompareNumberDecending)
        break;
      default:
        this.dataSource = this.data;
        break;
    }
  }

  sortByContactName() {
    const sortingState = this.sortingStateMachine(this.col1SortingActive, this.col1SortAcending);
    this.col1SortingActive = sortingState.isActive;
    this.col1SortAcending = sortingState.sortingDirection;
    let data = [...this.data];

    switch (this.col1SortAcending) {
      case true:
        this.dataSource = data.sort(CompareNameAcending)
        break;
      case false:
        this.dataSource = data.sort(CompareNameDecending)
        break;
      default:
        this.dataSource = this.data;
        break;
    }
  }
  sortByContactPhone() {
    const sortingState = this.sortingStateMachine(this.col2SortingActive, this.col2SortAcending);
    this.col2SortingActive = sortingState.isActive;
    this.col2SortAcending = sortingState.sortingDirection;
    let data = [...this.data];

    switch (this.col2SortAcending) {
      case true:
        this.dataSource = data.sort(ComparePhoneExistingAcending)

        break;
      case false:
        this.dataSource = data.sort(ComparePhoneExistingDecending)
        break;
      default:
        this.dataSource = this.data;
        break;
    }
  }

  sortByContactStatus() {
    const sortingState = this.sortingStateMachine(this.col3SortingActive, this.col3SortAcending);
    this.col3SortingActive = sortingState.isActive;
    this.col3SortAcending = sortingState.sortingDirection;
    let data = [...this.data];

    switch (this.col3SortAcending) {
      case true:
        this.dataSource = data.sort(CompareStatusExistingAcending)

        break;
      case false:
        this.dataSource = data.sort(CompareStatusExistingDecending)
        break;
      default:
        this.dataSource = this.data;
        break;
    }
  }

  sortingStateMachine(active: boolean, direction: boolean | null): { isActive: boolean, sortingDirection: boolean | null } {
    let isActive = active;
    let sortingDirection = direction;
    if (active === false) {
      isActive = true;
    }
    if (direction === null) {
      sortingDirection = true;
    }
    else if (direction === true) {
      sortingDirection = false;
    } else {
      sortingDirection = null;
      isActive = false;
    }

    return { isActive, sortingDirection };
  }


  onRightClick(event: IGridCellEventArgs) {
    console.log(event);
    event.event.preventDefault();

  }

  onCellClick(event: IGridCellEventArgs) {

    if (this.contactSearchOrigin === "case-roles") {
      this.sendFoundContact.emit(event.cell.row.data);
    } else {
      this.router.navigate(['contact', event.cell.row.data.Id]);
    }
  }


  rowSelection(event: IRowSelectionEventArgs) {
    this.selectedContact = event.newSelection[0];
    if (!this.isDesktopDevice) {
      if (this.contactSearchOrigin === "case-roles") {
        this.sendFoundContact.emit(event.newSelection[0]);
      } else {
        this.router.navigate(['contact', event.newSelection[0].Id]);
      }
    }

    if (this.isModal) {
      this.modalContactSelected.emit(event.newSelection[0]);
      this.self.destroy();
      return
    }

    if (this.contactSearchOrigin === "case-roles") {
      this.sendFoundContact.emit(event.newSelection[0])
    }
  }

  emptyGridMessage: string = "Søkeresultatet vil vises her"

  setStdXML() {
    return this.xml.StandardSearchXML({
      contactNumber: Number(this.contactNumber),
      contactName: this.contactName,
      activeUsers: this.activeUsers,
      fuzzySearch: this.fuzzySearch,
      address: this.contactAddress,
      com: this.coms,
      id: this.nId
    });
  }

  isPostalCodeRangeValid(): boolean {
    const fromCode = Number(this.postalCodeFrom);
    const toCode = Number(this.postalCodeTo);

    if (!this.postalCodeFrom && !this.postalCodeTo) {
      return true;
    }
    if (fromCode && toCode) {
      return toCode > fromCode;
    }
    return true;
  }


  validatePostalCodeInput() {
    this.isValid = true;
    this.resetInputStyles();
    if (this.postalCodeFrom?.length === 4 && this.postalCodeTo?.length === 4) {
      this.validateInput();
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  isValid: boolean = true;
  validateInput() {
    this.isValid = this.isPostalCodeRangeValid();
    if (!this.isValid) {
      const setStyles = (inputElement: any) => {
        inputElement.nativeElement.closest('.igx-input-group').style.setProperty('--focused-border-color', 'red');
        inputElement.nativeElement.closest('.igx-input-group').style.setProperty('--success-secondary-color', 'red');
        inputElement.nativeElement.closest('.igx-input-group').style.setProperty('--border-color', 'red');
        inputElement.nativeElement.closest('.igx-input-group').querySelector('label').style.color = 'red';
      };
      setStyles(this.zipFromInput);
      setStyles(this.zipToInput);
    } else {
      this.resetInputStyles()
    }
  }

  resetInputStyles() {
    const resetStyles = (inputElement: any) => {
      inputElement.nativeElement.closest('.igx-input-group').style.removeProperty('--focused-border-color');
      inputElement.nativeElement.closest('.igx-input-group').style.removeProperty('--success-secondary-color');
      inputElement.nativeElement.closest('.igx-input-group').style.removeProperty('--border-color');
      inputElement.nativeElement.closest('.igx-input-group').querySelector('label').style.color = '';
    };
    resetStyles(this.zipFromInput);
    resetStyles(this.zipToInput);
  }

  setAdvXML() {

    return this.xml.AdvancedSearchXML({
      status: this.status === '0' ? "" : this.status,
      roleTypeCatId: this.roleTypeCatId,
      roleTypeId: this.roleTypeId,
      caseId: this.caseId,
      incUser: this.incUser,
      countryId: this.countryId,
      postalCodeFrom: this.postalCodeFrom,
      postalCodeTo: this.postalCodeTo,
      businessSectorId: this.businessSectorId,
      distTypeId: this.distTypeId,
      diciplineId: this.diciplineId,
      diciplineCode: this.diciplineCode,
      activeUsers: this.activeUsers
    })

  }

  setUserXML(user = this.coWorker, activeUsers = this.activeUsers) {
    return this.xml.UserSearchXML({
      user,
      activeUsers
    })
  }


  tabletFilter: string = "";
  tabletFilterChange() {
    try {
      this.filteredDatasource = this.data.filter(item => {
        const { Number, Roles, ContactSectorCode, Name, Address, AllPhoneNumbers } = item;

        if (!isNaN(parseInt(this.tabletFilter))) {
          return String(Number).includes(this.tabletFilter);
        }

        if (this.tabletFilter.includes("nr:")) {
          let filterText = this.tabletFilter.replace("nr:", "")
          return String(Number).includes(filterText.toUpperCase())
        }
        if (this.tabletFilter.includes("s:")) {
          let filterText = this.tabletFilter.replace("s:", "")
          return ContactSectorCode.toUpperCase().includes(filterText.toUpperCase())
        }
        if (this.tabletFilter.includes("navn:")) {
          let filterText = this.tabletFilter.replace("navn:", "")
          return Name.toUpperCase().includes(filterText.toUpperCase())
        }
        if (this.tabletFilter.includes("adr:")) {
          let filterText = this.tabletFilter.replace("adr:", "")
          return (Address ?? "").toUpperCase().includes(filterText.toUpperCase())
        }
        if (this.tabletFilter.includes("com:")) {
          let filterText = this.tabletFilter.replace("com:", "")
          return (AllPhoneNumbers ?? "").toUpperCase().includes(filterText.toUpperCase())
        }
        if (this.tabletFilter.includes("roller:")) {
          let filterText = this.tabletFilter.replace("roller:", "")
          return (Roles ?? "").toUpperCase().includes(filterText.toUpperCase())
        }



        return ContactSectorCode.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || Name.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || (Address ?? "").toUpperCase().includes(this.tabletFilter.toUpperCase())
          || (AllPhoneNumbers ?? "").toUpperCase().includes(this.tabletFilter.toUpperCase())
          || (Roles ?? "").toUpperCase().includes(this.tabletFilter.toUpperCase())
      })

      if (this.filteredDatasource.length > 0) {
        this.grid_message = "Slutten av listen";
      } else {
        this.grid_message = "Ingen saker å vise";
      }

    } catch (err: any) {
    }
  }


  get isAnyStdInputFilled(): boolean {
    return !!(this.contactNumber || this.contactName || this.contactAddress || this.coms || this.nId);
  }
  get isAnyAdvInputFilled(): boolean {
    return !!(
      this.caseId ||
      this.roleTypeCatId ||
      this.roleTypeId ||
      (this.selectedValueKeys && this.selectedValueKeys.length > 0) ||
      this.countryCode ||
      this.postalCodeFrom ||
      this.postalCodeTo ||
      this.businessSectorId ||
      this.distTypeId
    );
  }

  get isCowInputFilled(): boolean {
    return !!this.coWorker;
  }

  onSearchTypeChange(event: any) {
    this.data = []
    if (event.newValue === '2') {
      this.data = this.coWorkerList;
    }

    this.contactNumber = '';
    this.contactName = '';
    this.contactAddress = '';
    this.coms = '';
    this.nId = '';
    this.caseId = null;
    this.roleTypeCatId = null;
    this.roleTypeId = null;
    this.selectedValueKeys = [];
    this.countryCode = null;
    this.postalCodeFrom = null;
    this.postalCodeTo = null;
    this.businessSectorId = null;
    this.distTypeId = null;
    this.canCreateNewContact = false;
  }

}


// isValid: boolean = true;
// validatePostalCodeInput() {
//   const isValidLength = this.postalCodeFrom?.length === 4 && this.postalCodeTo?.length === 4;
//   this.isValid = isValidLength ? this.isPostalCodeRangeValid() : false;

//   const updateUI = (valid: boolean) => {
//     const borderColor = valid ? '' : 'red';
//     [this.zipFromInput, this.zipToInput].forEach(input => {
//       const igxInputGroup = input.nativeElement.closest('.igx-input-group');
//       igxInputGroup.style.setProperty('--focused-border-color', borderColor);
//       igxInputGroup.style.setProperty('--border-color', borderColor);
//       igxInputGroup.querySelector('label').style.color = borderColor;
//     });
//   };
//   updateUI(this.isValid);
// }
