<div #modalRef></div>
<div class="search-container component-structure">
  <igx-expansion-panel #panel *ngIf="isMobile" [collapsed]=false
    (contentExpanding)="handleExpandingAndCollapsing($event); handleExpansion($event)"
    (contentCollapsing)="handleExpandingAndCollapsing($event); handleCollapse($event)">
    <igx-expansion-panel-header [iconPosition]="'none'">

      <igx-expansion-panel-title>
        <button igxButton="outlined" outlined>{{headerTitle}}</button>

      </igx-expansion-panel-title>
      @if (!canCreateNewContact) {
      <button igxButton [style.background]="'#bcbcbc'" igxButtonColor="#FFF" igxRipple="white"
        (click)="newContactClick($event)">
        Ny kontakt
      </button>
      }
      @if (canCreateNewContact && hasRightToAddNewContact) {
      <button igxButton="raised" [style.background]="'#D7F5AF'" igxButtonColor="#546342" igxRipple="white"
        (click)="newContactClick($event)">
        Ny kontakt
      </button>
      }

    </igx-expansion-panel-header>
    <igx-expansion-panel-body class="panel-body">
      <ng-container *ngTemplateOutlet="SearchBox"></ng-container>
    </igx-expansion-panel-body>
  </igx-expansion-panel>


  <ng-container *ngIf="!isMobile; then SearchBox"></ng-container>
  <ng-template #SearchBox>
    <div>
      <ng-content *ngIf="searchTypeId === '0'; then stdSearch"></ng-content>
      <ng-content *ngIf="searchTypeId === '1' ; then advSearch"></ng-content>
      <ng-content *ngIf="searchTypeId === '2' ;then cowSearch"></ng-content>
    </div>
  </ng-template>
</div>

<igx-grid igxPreventDocumentScroll #contactSearchGrid [data]="data" [displayDensity]="deviceService.gridDensity"
  [autoGenerate]="false" [ngClass]="isDesktopDevice ? 'contact-search-grid-desktop' : 'contact-search-grid-nonDesktop'"
  width="100%" class="grid-strech" [emptyGridMessage]="emptyGridMessage" (rowSelectionChanging)="rowSelection($event)"
  (doubleClick)="onCellClick($event)" emptyFilteredGridMessage="Filtrering gir ingen treff" [cellSelection]="'single'"
  [rowSelection]="'single'" (contextMenu)="onRightClick($event)" [hideRowSelectors]="true" sortable="true"
  [allowFiltering]=" isMobile ? false : true" [isLoading]="isLoading">
  <igx-column [width]="isDesktop ? '90px': '70px'" field="Number" [header]="'K.Nr'" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column [width]="isDesktop ? '100px': '50px'" field="ContactSectorCode" [header]="isDesktop? 'Sektor' : 'S.'"
    [sortable]="true" [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate">
  </igx-column>
  <igx-column field="Name" header="Navn" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="Address" header="Adresse" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="!isMobile" field="AllPhoneNumbers" header="Tlf/Epost/WWW" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="!isMobile" [width]="isDesktop ? '150px': '100px'" field="Roles" header="Roller" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-grid-footer class="grid-footer-container">
    <dlx-grid-item-count [isMobile]="isMobile" [current]="contactSearchGrid.totalRowsCountAfterFilter"
      [total]="data.length" />
  </igx-grid-footer>

</igx-grid>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="contactSearchGrid" [column]="column"></grid-filter-input>
</ng-template>

<ng-template #stdSearch>
  <div class="std-wrapper">
    <div class="std-search-layout">
      <div class="search-inputs">
        <igx-input-group displayDensity="compact" type="border" class="contact-number">
          <input #stdContactNumber igxInput name="contactNumber" type="number" [(ngModel)]="contactNumber" />
          <label igxLabel for="contactNumber">Kontaktnr:</label>
          @if(stdContactNumber.value.length > 0){
          <igx-icon igxSuffix (click)="contactNumber = null">close</igx-icon>
          }
        </igx-input-group>
        <igx-input-group displayDensity="compact" type="border" style="max-width:210px">
          <input #stdContactName igxInput name="contactName" type="text" [(ngModel)]="contactName" />
          <label igxLabel for="contactName">Navn:</label>
          @if(stdContactName.value.length > 0){
          <igx-icon igxSuffix (click)="contactName =  ''">close</igx-icon>
          }
        </igx-input-group>
        <igx-input-group displayDensity="compact" type="border" class="address" style="max-width:210px">
          <input #stdContactAdr igxInput name="contactAdr" type="text" [(ngModel)]="contactAddress" />
          <label igxLabel for="contactAdr">Adresse:</label>
          @if(stdContactAdr.value.length > 0){
          <igx-icon igxSuffix (click)="contactAddress = ''">close</igx-icon>
          }
        </igx-input-group>
        <igx-input-group displayDensity="compact" type="border" class="coms" style="max-width:210px">
          <input #stdComs igxInput name="coms" type="text" [(ngModel)]="coms" />
          <label igxLabel for="coms">Tlf/mail/web:</label>
          @if(stdComs.value.length > 0){
          <igx-icon igxSuffix (click)="coms = ''">close</igx-icon>
          }
        </igx-input-group>
        <igx-input-group displayDensity="compact" type="border" class="nId" style="max-width:210px">
          <input #stdNid igxInput name="nId" type="text" [(ngModel)]="nId" />
          <label igxLabel for="nId">Org.nr/Personnummer:</label>
          @if(stdNid.value.length > 0){
          <igx-icon igxSuffix (click)="nId = ''">close</igx-icon>
          }
        </igx-input-group>
      </div>
      <div class="flex-row">
        <div class="checkbox active">
          <input type="checkbox" name="activeUsers" id="activeUsers" [(ngModel)]="activeUsers">
          <label for="activeUsers">Bare aktive medarbeidere</label>
        </div>
        <div class="checkbox active">
          <input type="checkbox" name="fuzzySearch" id="fuzzySearch" [(ngModel)]="fuzzySearch">
          <label for="fuzzySearch">Fonetisk søk</label>
        </div>
      </div>
    </div>

    <div class="form-right">
      <div class="form-buttons">
        <ng-container
          *ngIf="canReset && isAnyStdInputFilled; then submitButtonEnabled; else submitButtonDisabled"></ng-container>
        <ng-container
          *ngIf="canCreateNewContact && hasRightToAddNewContact; then newContactEnabled else newContactDisabled"></ng-container>
      </div>
      <div>
        <ng-container *ngIf="true; then searchType"></ng-container>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #advSearch>
  <div class="adv-wrapper">
    <div class="adv-search-layout">
      <div class="simple-select-wrapper statusTypes">
        <label #caseStatusLabel igxLabel class="simple-select-label simple-select-label--selected">Status:</label>
        <igx-simple-combo class="simple-select-disable-reset" [data]="statusTypes" displayDensity="compact"
          (opening)="combo.comboOpening(caseStatusLabel)" (closing)="combo.comboClosing(caseStatusLabel, status)"
          [displayKey]="'label'" [valueKey]="'id'" [(ngModel)]="status" [type]="'border'">
        </igx-simple-combo>
      </div>

      <igx-input-group type="border" displayDensity="compact" class="input-s3 caseNum">
        <input #advCaseNum igxInput name="caseNum" type="number" [(ngModel)]="caseId" />
        <label igxLabel for="caseNum">Saksnr:</label>
        @if(advCaseNum.value.length > 0){
        <igx-icon igxSuffix (click)="caseId = ''">close</igx-icon>
        }
      </igx-input-group>

      <div class="simple-select-wrapper">
        <label #roleCatLabel igxLabel for="roleTypeId"
          class="simple-select-label roleTypeCategories">Rollekategori</label>
        <igx-simple-combo width="200px" [data]="roleTypeCategories" displayDensity="compact" [displayKey]="'Name'"
          [valueKey]="'Id'" [(ngModel)]="roleTypeCatId" [type]="'border'" (opening)="combo.comboOpening(roleCatLabel)"
          (closing)="combo.comboClosing(roleCatLabel, roleTypeCatId)"
          (selectionChanging)="onRoleTypeCategoryChange($event, roleSelect, roleLabel); combo.comboChanging($event, roleCatLabel)">
        </igx-simple-combo>
      </div>

      <div class="simple-select-wrapper roleType">
        <label #roleLabel igxLabel for="roleTypeId" class="simple-select-label">
          {{!(roles.length > 0) ? 'Velg Rollekategori': 'Rolle'}}</label>
        <igx-simple-combo width="200px" #roleSelect [data]="roles" (opening)="combo.comboOpening(roleLabel)"
          (closing)="combo.comboClosing(roleLabel, roleTypeId)"
          (selectionChanging)="combo.comboChanging($event, roleLabel)" displayDensity="compact" name="roleTypeId"
          [displayKey]="'Name'" [valueKey]="'Id'" [(ngModel)]="roleTypeId" [type]="'border'"
          [disabled]="!(roles.length > 0)">
        </igx-simple-combo>
      </div>

      <div class="simple-select-wrapper diciplines">
        <label #diciplineLabel igxLabel class="simple-select-label">Sak fagområdet</label>
        <igx-combo #comboSelect class="diciplines " [data]="diciplines" [type]="'border'" valueKey="Id"
          displayKey="Name" [filteringOptions]="filterOptions" (opening)="combo.comboOpening(diciplineLabel);"
          (closing)="combo.comboClosing(diciplineLabel, selectedValueKeys)" searchPlaceholder="filter..."
          (selectionChanging)="combo.multiComboChanging($event, diciplineLabel)" [(ngModel)]="selectedValueKeys"
          displayDensity="compact">
          <ng-template igxComboItem let-item let-key="valueKey">
            <div class="selectItem-content-wrapper" #target="tooltipTarget" [igxTooltipTarget]="tooltipRef">
              <span class="selectItem-shortname">{{item.Code}}</span>
              <span class="selectItem-name">{{item.Name}}</span>
            </div>
            <div #tooltipRef="tooltip" igxTooltip>
              {{item.Name}}
            </div>
          </ng-template>
        </igx-combo>
        <igx-badge #selectCount *ngIf="selectedValueKeys.length > 0" type="primary" class="badge-style">{{
          selectedValueKeys.length }}</igx-badge>
      </div>

      <div class="simple-select-wrapper country">
        <label #countryLabel igxLabel class="simple-select-label">Land:</label>
        <igx-simple-combo width="200px" [data]="countries" (opening)="combo.comboOpening(countryLabel)"
          (closing)="combo.comboClosing(countryLabel, countryCode)"
          (selectionChanging)="onCountryChange($event); combo.comboChanging($event, countryLabel)"
          displayDensity="compact" [displayKey]="'Country'" [valueKey]="'CountryCode'" [(ngModel)]="countryCode"
          [type]="'border'">
        </igx-simple-combo>
      </div>

      <!-- <igx-input-group type="border" displayDensity="compact" class="input-s3 postNumber">
        <input #zipInput igxInput name="zipFrom" type="text" (focus)="setCursor(zipInput)" [igxMask]="'0000 - 9999'"
          [(ngModel)]="postalCodeRange" />
        <label igxLabel for="zipFrom">Postnr fra - til:</label>
      </igx-input-group> -->


      <div class="postalCodeRange">
        <div class="postalCodeRangeIputs">

          <igx-input-group type="border" displayDensity="compact" class="input-s3 postNumber custom-focused-input">

            <input #zipFromInput igxInput name="zipFrom" type="text" [(ngModel)]="postalCodeFrom"
              (ngModelChange)="validatePostalCodeInput()" maxlength="4" (keypress)="numberOnly($event)" />
            <label igxLabel for="zipFrom">Postnr fra:</label>
            @if(zipFromInput.value.length > 0){
            <igx-icon igxSuffix (click)="postalCodeFrom = ''">close</igx-icon>
            }
            <span igxHint *ngIf="!isValid" class="validation-message">* Postnummerområdet er ugyldig.</span>

          </igx-input-group>

          <igx-input-group type="border" displayDensity="compact" class="input-s3 postNumber custom-focused-input">
            <input #zipToInput igxInput name="zipTo" type="text" [(ngModel)]="postalCodeTo"
              (ngModelChange)="validatePostalCodeInput()" maxlength="4" (keypress)="numberOnly($event)" />
            <label igxLabel for="zipTo">Postnr til:</label>
            @if(zipToInput.value.length > 0){
            <igx-icon igxSuffix (click)="postalCodeTo = ''">close</igx-icon>
            }
          </igx-input-group>
        </div>
      </div>

      <div class="simple-select-wrapper businessSectors">
        <label #businessSectorLabel igxLabel class="simple-select-label">Bransje:</label>
        <igx-simple-combo width="200px" [data]="businessSectors" (opening)="combo.comboOpening(businessSectorLabel)"
          (closing)="combo.comboClosing(businessSectorLabel, businessSectorId)"
          (selectionChanging)="combo.comboChanging($event, businessSectorLabel)" displayDensity="compact"
          [disabled]="!(businessSectors.length > 0)" [displayKey]="'Name'" [valueKey]="'Id'"
          [(ngModel)]="businessSectorId" [type]="'border'">
        </igx-simple-combo>
      </div>

      <div class="simple-select-wrapper distTypes">
        <label #distTypeLabel igxLabel class="simple-select-label">Dist. Kode:</label>
        <igx-simple-combo width="200px" [data]="distTypes" (opening)="combo.comboOpening(distTypeLabel)"
          (closing)="combo.comboClosing(distTypeLabel, distTypeId)"
          (selectionChanging)="combo.comboChanging($event, distTypeLabel)" displayDensity="compact"
          [disabled]="!(distTypes.length > 0)" [displayKey]="'Name'" [valueKey]="'Id'" [(ngModel)]="distTypeId"
          [type]="'border'">
        </igx-simple-combo>
      </div>

      <div class="checkbox active">
        <input type="checkbox" name="activeUsers" id="activeUsers" [(ngModel)]="activeUsers">
        <label for="activeUsers">Bare aktive medarbeidere</label>
      </div>
      <div class="checkbox incUser">
        <input type="checkbox" name="incUser" id="incUser" [(ngModel)]="incUser">
        <label for="incUser">Ta med medarbeidere</label>
      </div>
    </div>

    <div class="form-right">
      <div class="form-buttons">
        <button class="m-contact-search-button" id="submit" igxButton="raised" igxButtonColor="white"
          [style.background]="isAnyAdvInputFilled ? '#575757' : '#BCBCBC'" igxRipple="white"
          (click)="onSubmit(); canCreateNewContact = true" [disabled]="!isAnyAdvInputFilled">SØK</button>

        <ng-container
          *ngIf="canCreateNewContact && hasRightToAddNewContact; then newContactEnabled else newContactDisabled"></ng-container>
      </div>
      <ng-container *ngIf="true; then searchType"></ng-container>
    </div>

  </div>
</ng-template>

<ng-template #cowSearch>
  <div class="cow-wrapper">
    <div class="flex-col">
      <div class="search-inputs">
        <igx-input-group type="border" displayDensity="compact" style="max-width: 210px;">
          <input #coWorkerInput igxInput name="coWorker" type="text" [(ngModel)]="coWorker" />
          <label igxLabel for="coWorker">Medarbeider:</label>
          @if(coWorkerInput.value.length > 0){
          <igx-icon igxSuffix (click)="coWorker = ''">close</igx-icon>
          }
        </igx-input-group>
        <span>Du kan søke med * (wildcard)</span>
      </div>
      <div class="search-checkboxes">
        <div class="checkbox">
          <input type="checkbox" name="activeUsers" id="activeUsers" [(ngModel)]="activeUsers">
          <label for="activeUsers">Bare aktive medarbeidere</label>
        </div>
      </div>
    </div>

    <div class="form-right">
      <div class="form-buttons">
        <button class="m-contact-search-button" id="submit" igxButton="raised" igxButtonColor="white"
          [style.background]="isCowInputFilled ? '#575757' : '#BCBCBC'" igxRipple="white" (click)="onSubmit()"
          [disabled]="!isCowInputFilled">SØK</button>
      </div>
      <ng-container *ngIf="true; then searchType"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #newContactEnabled>
  <button *ngIf="!isMobile" class="m-contact-search-button" id="newContact" igxButton="raised" igxButtonColor="white"
    [style.background]="'#AEC965'" igxRipple="white" (click)="onNewContact()">NY KONTAKT</button>
</ng-template>
<ng-template #newContactDisabled>
  <button [disabled]="true" [ngClass]="isMobile ? 'm-contact-search-button button-display' : 'm-contact-search-button'"
    id="newContact" igxButton="raised" igxButtonColor="white" [style.background]="'#BCBCBC'" igxRipple="white">NY
    KONTAKT</button>
</ng-template>
<ng-template #submitButtonEnabled>
  <button class="m-contact-search-button" id="submit" igxButton="raised" igxButtonColor="white"
    [style.background]="'#575757'" igxRipple="white" (click)="onSubmit()">SØK</button>
</ng-template>
<ng-template #submitButtonDisabled>
  <button [disabled]="true" class="m-contact-search-button" id="submit" igxButton="raised" igxButtonColor="white"
    [style.background]="'#BCBCBC'" igxRipple="white" (click)="onSubmit()">SØK</button>
</ng-template>

<ng-template #resetEnabled>
  <button class="m-contact-search-button" id="reset" igxButton="raised" igxButtonColor="white"
    [style.background]="'#AEC965'" igxRipple="white" (click)="reset()">NULLSTILL</button>
</ng-template>

<ng-template #resetDisabled>
  <button [disabled]="true" class="m-contact-search-button" id="reset" igxButton="raised" igxButtonColor="white"
    [style.background]="'#BCBCBC'" igxRipple="white">NULLSTILL</button>
</ng-template>

<ng-template #newSearch>
  <button class="m-contact-search-button" id="newSearch" igxButton="raised" igxButtonColor="white"
    [style.background]="'#575757'" (click)="onNewSearch()" igxRipple="white">NYTT SØK</button>
</ng-template>

<ng-template #searchBtn>
  <button class="m-contact-search-button" id="submit" igxButton="raised" igxButtonColor="white"
    [style.background]="'#AEC965'" igxRipple="white" (click)="onSubmit()">SØK</button>
  <button class="m-contact-search-button" igxButton="raised" igxButtonColor="white" [style.background]="'#575757'"
    igxRipple="white" (click)="reset()">NULLSTILL</button>
</ng-template>


<ng-template #searchType>
  <div *ngIf="!isMobile" class="simple-select-wrapper">
    <label #searchType igxLabel class="simple-select-label simple-select-label--selected">Type:</label>
    <igx-simple-combo [data]="searchTypes" class="simple-select-disable-reset"
      (opening)="combo.comboOpening(searchType)" (closing)="combo.comboClosing(searchType, searchTypeId)"
      (selectionChanging)="combo.searchTypeChanging($event); onSearchTypeChange($event) " displayDensity="compact"
      [displayKey]="'label'" [valueKey]="'id'" [(ngModel)]="searchTypeId" [type]="'border'">
    </igx-simple-combo>
  </div>
</ng-template>